.FailPage-container {
    display: flex;
    flex-direction: column;
}

.FailPage-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 40px;
}

.FailPage-title {
    font-size: 42px;
}

.FailPage-cross {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    height: 82px;
    width: 82px;
    border-radius: 50%;
    background: #FAE7E4;
    margin-right: 32px;
}

.FailPage-subtitle {
    font-size: 18px;
    margin-bottom: 40px;
}

.FailPage-subtitle-footer {
    font-size: 18px;
    margin-top: 40px;
}

@media (max-width: 900px) {
    .FailPage-header {
        margin-bottom: 20px;
    }

    .FailPage-title {
        font-size: 32px;
    }

    .FailPage-checkmark {
        margin-right: 16px;
    }

    .FailPage-subtitle {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .FailPage-subtitle-footer {
        font-size: 16px;
        margin-top: 20px;
    }
}
