.Button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #8C9EEB;
    border-radius: 16px;
    cursor: pointer;
    flex-grow: 1;
    height: 80px;
    margin-bottom: 14px;
}

.Button.disabled {
    background-color: #F2F2F2;
    pointer-events: none;
}

.Button:hover {
    opacity: 0.95;
}

.Button-title {
    font-size: 20px;
    color: white;
    font-weight: 400;
    text-align: center;
    flex-shrink: 0;
}

.Button-title.disabled {
    color: #B5B5B5;
}

@media (max-width: 900px) {
    .Button-title {
        font-size: 16px;
    }

    .Button {
        height: 60px;
    }
}
