@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    box-sizing: border-box;
}

body {
    background-color: #FBFBFB;
}

#root {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    width: 100%;
}

.App {
    display: flex;
    flex-direction: column;
    max-width: 750px;
    margin: 32px;
    text-align: left;
}

.Navigator-nav {
    margin-bottom: 90px;
}

.App-container {

}

.disabled {
    opacity: 50%;
    pointer-events: none;
}

.App-Section {
    margin-bottom: 40px;
}

.App-header {
    font-weight: 500;
    font-size: 48px;
    color: #000000;
    margin-bottom: 65px;
}

.App-header span:first-of-type {
    color: #8C9EEB;
}

.App-title {
    color: #000000;
    margin-bottom: 14px;
}

.App-subtitle {
    font-size: 16px;
    color: #808080;
    margin-bottom: 14px;
}

.App-subtitle > a {
    font-size: inherit;
    text-decoration: underline;
    text-decoration-thickness: 0;
    text-decoration-color: #8C9EEB;
    cursor: pointer;
    line-height: 22px;
}

.App-input-user-id-container {
    position: relative;
}

.App-input-user-id {
    padding: 24px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    font-size: 20px;
    outline: none;
    max-width: 750px;
    width: 100%;
    border: 1px solid transparent;
}

.App-input-user-id.extra-padding {
    padding-right: 150px;
}

.App-account-validating-state {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 15px;
    top: 16px;
    align-self: center;
    border-radius: 32px;
    padding: 12px 16px;
    font-size: 14px;
}

.App-account-validating-state.validating {
    color: #6D84E4;
    background: #EDF0FF;
}

.App-account-validating-state.correct {
    color: #659C3A;
    background: #EEFAE4;
}

.App-account-validating-state.wrong {
    color: #9C4C3A;
    background: #FAE7E4;
}

.App-input-user-id.invalid {
    border: #9C4C3A solid 1px;
}

.App-input-invalid-label {
    font-size: 12px;
    color: #9C4C3A;
}

.App-wrong-user-id-error {
    font-size: 16px;
    color: #9C4C3A;
    text-align: center;
    display: inline-block;
}

.App-selectable-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 24px;
    border-radius: 16px;
    margin-bottom: 16px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
    cursor: pointer;
    border: 2px solid transparent;
}

.App-selectable-box.active {
    border: 2px solid #8C9EEB;
}

.App-selectable-box-title {
    font-size: 16px;
}

.App-selectable-box-subtitle {
    font-size: 16px;
    text-align: right;
}

.App-radio-button-outside {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid #8C9EEB;
    margin-right: 18px;
    flex-shrink: 0;
}

.App-radio-button-active {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #8C9EEB;
    flex-shrink: 0;
}

.spacer {
    flex-grow: 1;
}

a {
    font-size: 18px;
    color: #8C9EEB;
    text-decoration: underline;
    text-underline-offset: 6px;
}

.Navigator-a {
    margin-right: 24px;
}

a.active {
    color: black;
    pointer-events: none;
    text-decoration: none;
}

@media (max-width: 900px) {
    .App {
        margin: 20px;
    }

    .App-header {
        font-size: 36px;
        margin-bottom: 45px;
    }

    .App-title {
        font-size: 14px;
        margin-bottom: 8px;
    }

    .App-subtitle {
        font-size: 12px;
        margin-bottom: 8px;
    }

    .App-input-user-id {
        padding: 16px;
        font-size: 16px;
    }

    .App-input-user-id.extra-padding {
        padding-right: 120px;
    }

    .App-selectable-box {
        padding: 16px;
        margin-bottom: 12px;
    }

    .App-radio-button-outside {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 14px;
        width: 14px;
    }

    .App-radio-button-active {
        height: 8px;
        width: 8px;
    }

    .App-selectable-box-title {
        font-size: 14px;
    }

    .App-selectable-box-subtitle {
        font-size: 14px;
    }

    .App-button-title {
        font-size: 16px;
    }

    .App-button {
        height: 60px;
    }

    .App-account-validating-state {
        right: 15px;
        top: 12px;
        padding: 8px 12px;
        font-size: 12px;
    }

    a {
        font-size: 16px;
    }
}
